.feedback-options {
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  margin-top: 15px;
}

.feedback-option {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1rem;
  border: 2px solid #E4E4E7;
  border-radius: 12px;
  transition: border-color 0.3s ease;
}

.feedback-option:hover {
  cursor: pointer;
}

.feedbackMessage-textarea {
  font-weight: 400;
  font-size: 15px;
  border: 2px solid #CACACA;
  width: 100%;
  resize: none;
  height: 240px;
  border-radius: 12px;
  padding: 15px;
}