.sucess-container{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.sucess-p{
color: var(--gray-600, #52525B);
text-align: center;
font-feature-settings: 'clig' off, 'liga' off;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 150% */
letter-spacing: -0.4px;
margin-top: 12px;
margin-bottom: 23px;
padding: 0px 35px;
}

.success-img{
    margin-bottom: 23px;
}

.sucess-container {
  opacity: 0; 
  transition: opacity 0.3s ease-in-out; 
}

.sucess-container.transition-delay {
  opacity: 1; 
  transition-delay: 2s; 
}
.password-input-flex{
  display: flex;
  align-items: center;

}
.show-password{
  position: absolute;
  right: 17px;
  cursor: pointer;
}