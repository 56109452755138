.loginFormWrpFeedBack {
    width: 475px;
    background-color: #fff;
    padding: 13px 20px;
    border-radius: 23px;
    box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.1);
  }
  
  .feedback-div-default-border-feedback {
    border: 2px solid rgba(228, 228, 231, 1);
  }
  
  .feedback-div-green-border-feedback {
    border: 2px solid rgba(20, 184, 166, 1);
  }
  
  .feedback-reason-div-feedback {
    border-radius: 12px;
    padding: 12px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .checkbox-round {
    width: 20px;
    height: 20px;
    background-color: white;
    border-radius: 50%;
    vertical-align: middle;
    border: 2px solid #ddd;
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
  }
  
  .feedback-cancel-text {
    display: flex;
    font-size: 14px;
    font-family: Neurialgrotesk, sans-serif;
    font-weight: 500;
  }
  
  .message-cancel-div {
    font-size: 14px;
    outline: none;
    width: 100%;
    border: 2px solid rgba(228, 228, 231, 1);
    border-radius: 12px;
    height: 125px;
    font-family: Neurialgrotesk, sans-serif;
    font-weight: 500;
  }
  
  .tick-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 12px;
    height: 12px;
  }
  
  .checkbox-round {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid #ccc;
    display: inline-block;
    margin-left: 10px;
    position: relative;
  }
  
  .checkbox-round.selected {
    background-color: #469b88; /* Theme green color */
    border-color: #469b88; /* Theme green color */
  }
  
  .message-cancel-div::placeholder {
    color: #D3D3D3; /* Light gray */
  }
  
  .comn-btn:first-child {
    margin-left: 0px;
  }
  
  .sign-btn {
    padding: 16px 147px !important;
    box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.1);
    font-family: 'Neurialgrotesk', sans-serif;
    font-weight: 500;
  }