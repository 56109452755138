.toggle-button-btn-container {
  display: flex;
  gap: 10px;
  padding: 2px;
  background-color: white;
  border-radius: 32px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.toggle-button-btn {
  padding: 10px 45px;
  border: none;
  border-radius: 32px;
  cursor: pointer;
  color:#9E9E9E;;
  font-size: 16px;
  background-color: #ffffff;
  transition: background-color 0.3s, color 0.3s;
}

.toggle-button-btn.active {
  color: white;
}

.toggle-button-btn:not(.active):hover {
  background-color:  #ffffff;
}

.toggle-button-btn.active-income {
  background-color: #479b88; 
}

.toggle-button-btn.active-expenses {
  background-color: #FF8484;; 
}

.categorie-count {
  color: #8A8A8A;
  font-size: 15px;
  margin-top: 2px;
  margin-left: 5px;
}

.all-categorie-title {
  font-size: 13px;
  padding-left: 7px;
  padding-right: 7px;
  color: #4C4C4C;
}

.chart-container {
  background-color: white; 
  border-radius: 15px; 
  padding: 20px; 
  box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.10);
  
}

.chart-container h2{
  color: #000;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
}

.doughnut-card{
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  height: 100%;
  width: 45%;
}

/* Container for the grid layout */
.grid-container {
display: grid;
grid-template-columns: repeat(2, 1fr);
grid-gap: 20px;
padding: 20px;
}

/* Container for the grid layout */
.according-item-money {
padding: 14px;
background: #fff;
border-radius: 10px;
box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.10);
cursor: pointer;
overflow: hidden;
width: 100%;
}

.merchant-according-item-money {
  padding: 14px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.10);
  cursor: pointer;
  overflow: hidden;
  width: 100%;
}

.merchants-wrappper-left-side {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 15px; /* Maintain spacing between items */
  background-color: #fff;
  padding: 28px;
  border-radius: 15px;
  box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.1);
  width: 100%; /* Ensure it uses the full width of its container */
}

/* Add a media query for screens under 1080px */
@media (max-width: 1500px) {
  .merchants-wrappper-left-side {
    grid-template-columns: 1fr 1fr;
  }
}

/* Add a media query for very small screens, e.g., under 480px */
@media (max-width: 1100px) {
  .merchants-wrappper-left-side {
    grid-template-columns: 1fr;
  }
}


.category-breakdown-row {
  display: flex;
  grid-template-columns: 1fr 1fr; /* Two equal columns */
  gap: 15px; /* Adds a gap between the columns */
  align-items: start; /* Aligns the items at the top */
}

.category-card{
  display: flex;
  width: 100%;
  height: 100%;
}

.accordion-header {
display: flex;
justify-content: space-between;
align-items: center;
}

.account-img {
width: 40px;
height: 40px;
}

.account-text {
flex-grow: 1;
}

.account-name {
font-weight: bold;
font-size: 16px;
}

.account-subtext {
font-size: 14px;
color: #888;
}

.account-value {
font-size: 18px;
font-weight: bold;
color: #4caf50;
}
.account-value-expenses {
font-size: 18px;
font-weight: bold;
color: #E25C5C;
}

/* Accordion content */
.accordion-content {
max-height: 0;
overflow: hidden;
transition: max-height 0.5s ease, opacity 0.5s ease;
opacity: 0; /* Hidden initially */
}

.accordion-content.expanded {
max-height: 200px; /* Adjust based on content */
opacity: 1;
}

/* Accordion content that will fade in/out */
.accordion-inner {
visibility: hidden;
opacity: 0;
transition: visibility 0s linear 0.3s, opacity 0.3s ease-in-out;
}

.accordion-content.expanded .accordion-inner {
visibility: visible;
opacity: 1;
transition: visibility 0s linear 0s, opacity 0.3s ease-in-out;
}

.detail-item {
display: flex;
justify-content: space-between;
padding: 5px 0;

}

.top-header-switch-box {
display: flex;
justify-content: space-between;
padding: 3px;
box-shadow: 0px 5px 30px 0px #0000001A;
background-color: #FFFFFF;
border-radius: 32px;
}

.divider {
  height: 1px;
  background-color: #F0F0F0;
  border: none;
}

.money-insight-detail-left {
  flex: 3.5;
  min-width: 300px;
}
.money-insight-detail-right {
  flex: 2;
  min-width: 300px;
}

.custom-calendar-size {
  width: 100% !important;  /* Adjust width as needed */
}

.custom-calendar-size .rdrMonth {
  width: 100% !important;
  height: 100% !important;
}

.modal-content-calendar {
  max-width: 400px;
  /* padding: 20px; */
}

.button-container {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  margin-top: 0px;
}

.text-button {
  background: none;
  border: none;
  color: #469B88;
  font-size: 16px;
  cursor: pointer;
}

.text-button:hover {
  text-decoration: underline;
}
.add-rule-width-bottom {
  width: 600px !important;
  border-radius: 20px !important;
  padding:20px 31px 31px 31px !important
}

