.save-button-budget-detail, .next-button-budget-detail {
    padding: 10px;
    width: 150px;
    margin-right: 10px;
    border-radius: 22px;
    border: none;
    cursor: pointer;
}

.save-button-budget-detail {
    background-color: #469B88;
    color: white;
}

.next-button-budget-detail {
    background-color: #D04F4F;
    color: white;
}

/* Expense form styles */
.expense-form {
    margin-top: 20px;
}

.expense-header {
    display: flex;
    /* justify-content: space-between; */
    padding: 10px;
    font-weight: bold;
   
}

.header-item {
    display: flex;
    flex-direction: row;
}

/* Styles for Expense Items */
.expense-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
}

.expense-item input[type="text"] {
    padding: 10px;
    margin-right: 10px;
    border-radius: 10px;
    width: 343px;
    border: none;
    background-color: #EFEDED;
}

.expense-item label {
    color: var(--Base-100, #242424);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
}

.weekly-button, .need-what-button {
    padding: 8px;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    color: white;
    width: 133px;
}

.weekly-button {
    background-color: #4CAF50;
}



.popup-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5); 
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  
  .popup-content {
    background: white;
    border-radius: 10px;
    padding: 20px;
    max-width: 400px;
    width: 90%;
    text-align: center;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  }
  
  
  .popup-content h2 {
    margin-bottom: 10px;
  }
  
  
  .popup-buttons {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-top: 20px;
  }
  
  
  .btn {
    flex: 1;
    padding: 10px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    color: white;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  
  .btn-no {
    background-color: #cf5050; 
  }
  
  .btn-no:hover {
    background-color: #b04444; 
  }
  
  
  .btn-yes {
    background-color: #469985; 
  }
  
  .btn-yes:hover {
    background-color: #3a816f; 
  }
  