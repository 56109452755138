@font-face {
  font-family: 'Neurialgrotesk';
  src: url('https://uploads-ssl.webflow.com/6295c7949b041d1ca927834f/6295caafc2886377d58490d8_NeurialGrotesk-Bold.otf')
      format('opentype'),
    url('https://uploads-ssl.webflow.com/6295c7949b041d1ca927834f/6295caaf59d138f741e5fc77_NeurialGrotesk-Extrabold.otf')
      format('opentype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Neurialgrotesk';
  src: url('https://uploads-ssl.webflow.com/6295c7949b041d1ca927834f/6295caafe9bad34580d6a81b_NeurialGrotesk-ExtraboldItalic.otf')
      format('opentype'),
    url('https://uploads-ssl.webflow.com/6295c7949b041d1ca927834f/6295caaf9b041d495e27948a_NeurialGrotesk-BoldItalic.otf')
      format('opentype');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Neurialgrotesk';
  src: url('https://uploads-ssl.webflow.com/6295c7949b041d1ca927834f/6295caaf3222799eb0c2fbe3_NeurialGrotesk-Italic.otf')
    format('opentype');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Neurialgrotesk';
  src: url('https://uploads-ssl.webflow.com/6295c7949b041d1ca927834f/6295caaf9d4478ec493b2f1e_NeurialGrotesk-Light.otf')
    format('opentype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Neurialgrotesk';
  src: url('https://uploads-ssl.webflow.com/6295c7949b041d1ca927834f/6295cab0f5b483166ec54b0f_NeurialGrotesk-Regular.otf')
    format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Neurialgrotesk';
  src: url('https://uploads-ssl.webflow.com/6295c7949b041d1ca927834f/6295cab020598e191aef5fc2_NeurialGrotesk-LightItalic.otf')
    format('opentype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Neurialgrotesk';
  src: url('https://uploads-ssl.webflow.com/6295c7949b041d1ca927834f/6295cab09330f7deb6c477ab_NeurialGrotesk-Medium.otf')
    format('opentype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Neurialgrotesk';
  src: url('https://uploads-ssl.webflow.com/6295c7949b041d1ca927834f/6295cab0cd89fc24cd355ffd_NeurialGrotesk-MediumItalic.otf')
    format('opentype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}
body {
  margin: 0;
  font-family: Neurial Grotesk;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.settings-logos-main {
  overflow: hidden;
  width: 59px;
  height: 50px;
}
.settings-logos {
  width: 50px;
  height: 50px;
  object-fit: cover;
}
.app-sidebar {
  display: flex;
  flex-direction: column;
  height: 100vh; /* Full height of the viewport */
  position: fixed;
  left: 0;
  top: 0;
  width: 250px; /* Adjust sidebar width as needed */
  background-color: #f0f0f0;
  overflow: hidden;
}

.scrollbar-sidebar {
  flex: 1; /* Take up remaining space */
  overflow-y: auto; /* Make content scrollable if necessary */
  overflow: hidden;
}

.not-found-container {
  width: 100%;
  height: 100vh; 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.vertical-nav-menu {
  display: flex;
  flex-direction: column;
  height: 100%; /* Ensure it fills the sidebar */
}

.app-sidebar__inner {
  display: flex;
  flex-direction: column;
  flex-grow: 1; /* Ensure it stretches to occupy available space */
}

.beta-section {
  margin-top: auto; /* Pushes it to the bottom */
  text-align: center;
  margin-left: 15px;
}

.beta-banner {
  background-color: #8232FF;
  color: white;
  border-radius: 8px;
  padding: 10px 10px;
  font-weight: 500;
  font-size: 14px;
}

.beta-message {
  margin-top: 10px;
  color: #000000;
  font-size: 12px;
}
