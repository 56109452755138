.small-modal {
    position: absolute;
    top: 60px;
    left: 50%;
    transform: translateX(-50%);
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1001;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
    width: 300px;
    align-items: center;
}

.small-modal-header {
    grid-column: span 2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.small-modal-header label {
    font-size: 16px;
    font-weight: bold;
}

.small-modal button {
    padding: 10px 20px;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    color: white;
    min-width: 100px;
    text-align: center;
    flex: 1;
}

.small-modal button:hover {
    background-color: #ddd;
}

/* Close button style */
.small-modal-close-button {
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
}


/* Existing Modal CSS (your code) */

.modal-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #0003;
    z-index: 1000;
    /* Ensure it's on top of other content */
}

.modal-container {
    background-color: white;
    padding: 30px;
    padding-top: 24px;
    border-radius: 15px;
    width: 90%;
    max-width: 500px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: relative;
}

.modal-section-card {
    border-radius: 10px;
    border: 2px solid #FFF;
    background: #FFF;
    box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.10);
    padding: 15px;
}

.modal-container p {
    color: var(--Base-100, #2C3131);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 20px;
}

.modal-container h2 {
    color: #000;
    font-feature-settings: 'liga' off, 'clig' off;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 10px;
}

.close-button {
    position: absolute;
    top: 28px;
    right: 24px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
}

.modal-content {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.modal-input-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
}

.modal-input-group span {
    color: #A7A7A7;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    border: 1px solid #A7A7A7;
    padding: 6px;
    border-radius: 40px;
    cursor: pointer;
}

.modal-input-group label {
    flex: 1;
    color: var(--Base-100, #242424);
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
}

.modal-input-group input,
.modal-input-group select {
    width: 199px;
    padding: 12px;
    padding-top: 4px;
    padding-bottom: 4px;
    border: 1px solid gray;
    border-radius: 40px;
    outline: none;
}

.modal-budget-content h2 {
    color: var(--Base-100, #242424);
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
}

.toggle-button,
.color-button,
.emoji-button {
    border: none;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    padding: 8px 15px;
    border-radius: 10px;
    background-color: #EFEDED;
    cursor: pointer;
    min-width: 80px;
    text-align: center;
}

.modal-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.save-button {
    background-color: #469B88;
    color: var(--base-white, #FFF);
    font-feature-settings: 'liga' off, 'clig' off;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    border-radius: 12px;
    display: flex;
    width: 214px;
    padding: 10px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border: none;
}

.next-button {
    background-color: #008ABE;
    color: var(--base-white, #FFF);
    font-feature-settings: 'liga' off, 'clig' off;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    border-radius: 12px;
    display: flex;
    width: 214px;
    padding: 10px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border: none;
}

.color-picker-wrapper {
    width: 300px;
}