.flex {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.delete-account-para {
  font-size: 12px;
  font-weight: 400;
  line-height: 16.8px;
}

.delete-section-model {
  flex-shrink: 0;
  border-radius: 20px;
  background: #FFF;
  padding: 14px 20px;
}

.change-password-btn {
  box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.1);
  background-color: #469B88;
  width: 340px;
  height: 50px;
  padding: 10px;
  border-radius: 12px;
  border: none;
  color: var(--base-white, #FFF);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.4px;
}

.forgot-btn {
  padding: 16px 130px !important;
  box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.1);
  font-family: 'Neurialgrotesk', sans-serif;
  font-weight: 500;
}

.forgot-btn-load {
  padding: 16px 168px !important;
  box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.1);
  font-family: 'Neurialgrotesk', sans-serif;
  font-weight: 500;
}


.verifyresetpassword-btn {
  padding: 16px 94px !important;
  box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.1);
  font-family: 'Neurialgrotesk', sans-serif;
  font-weight: 500;
}

.verifyresetpassword-btn-load {
  padding: 16px 171px !important;
  box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.1);
  font-family: 'Neurialgrotesk', sans-serif;
  font-weight: 500;
}

.show-password-delete-acc{
  position: absolute;
  right: 40px;
  cursor: pointer;
}

.change-password-btn.breathing-effect {
  animation: breathing 1.5s infinite ease-in-out;
}

@keyframes breathing {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  50% {
    transform: scale(1.02);
    opacity: 0.7;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}