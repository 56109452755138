@font-face {
  font-family: 'Neurialgrotesk';
  src: url('https://uploads-ssl.webflow.com/6295c7949b041d1ca927834f/6295caafc2886377d58490d8_NeurialGrotesk-Bold.otf')
      format('opentype'),
    url('https://uploads-ssl.webflow.com/6295c7949b041d1ca927834f/6295caaf59d138f741e5fc77_NeurialGrotesk-Extrabold.otf')
      format('opentype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Neurialgrotesk';
  src: url('https://uploads-ssl.webflow.com/6295c7949b041d1ca927834f/6295caafe9bad34580d6a81b_NeurialGrotesk-ExtraboldItalic.otf')
      format('opentype'),
    url('https://uploads-ssl.webflow.com/6295c7949b041d1ca927834f/6295caaf9b041d495e27948a_NeurialGrotesk-BoldItalic.otf')
      format('opentype');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Neurialgrotesk';
  src: url('https://uploads-ssl.webflow.com/6295c7949b041d1ca927834f/6295caaf3222799eb0c2fbe3_NeurialGrotesk-Italic.otf')
    format('opentype');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Neurialgrotesk';
  src: url('https://uploads-ssl.webflow.com/6295c7949b041d1ca927834f/6295caaf9d4478ec493b2f1e_NeurialGrotesk-Light.otf')
    format('opentype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Neurialgrotesk';
  src: url('https://uploads-ssl.webflow.com/6295c7949b041d1ca927834f/6295cab0f5b483166ec54b0f_NeurialGrotesk-Regular.otf')
    format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Neurialgrotesk';
  src: url('https://uploads-ssl.webflow.com/6295c7949b041d1ca927834f/6295cab020598e191aef5fc2_NeurialGrotesk-LightItalic.otf')
    format('opentype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Neurialgrotesk';
  src: url('https://uploads-ssl.webflow.com/6295c7949b041d1ca927834f/6295cab09330f7deb6c477ab_NeurialGrotesk-Medium.otf')
    format('opentype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Neurialgrotesk';
  src: url('https://uploads-ssl.webflow.com/6295c7949b041d1ca927834f/6295cab0cd89fc24cd355ffd_NeurialGrotesk-MediumItalic.otf')
    format('opentype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

.text-green {
  color: #469b88;
}

.not-found-text {
  color: rgb(36, 15, 81);
  font-weight: 600;
  font-family: 'NeurialGrotesk-Regular' !important;
}

.text-green-bold {
  color: #469b88;
  font-weight: bold;
}
.sign-btn {
  padding: 16px 147px !important;
  box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.1);
  font-family: Neurialgrotesk, sans-serif;
  font-weight: 500;
}
.sign-btn-load {
  padding: 16px 165px !important;
  box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.1);
  font-family: Neurialgrotesk, sans-serif;
  font-weight: 500;
}
.forgot-text {
  font-family: Neurialgrotesk, sans-serif;
  font-weight: 500;
  font-size: 12px;
  margin-top: -10px;
}

.cancel-plan-btn {
  padding: 16px 85px !important;
  box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.1);
}
.whiteBackGroundWrapper {
  width: 200px;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Adjust the shadow as needed */
  margin: 20px; /* Optional: Add margin for spacing */
  cursor: pointer;
}
.textContainer {
  color: rgba(183, 184, 193, 1);
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: -0.14px;
  font-family: Neurialgrotesk, sans-serif;
}

.notFoundContainer {
  color: #8F8F8F;
  font-size: 15px !important;
  font-style: normal;
  font-weight: 400;
  font-family: Neurialgrotesk, sans-serif;
}

.LoginText {
  color: #240f51;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  font-family: Neurialgrotesk, sans-serif;
}

.emailText {
  color: #240f51;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  font-family: Neurialgrotesk, sans-serif;
}
.verification-box {
  width: 48px;
  height: 48px;
  font-size: 16px;
  text-align: center;
  border: 2px solid #d0d0d0;
  border-radius: 7px;
  margin-right: 7px;
  margin-left: 7px;
  font-family: 'Neurialgrotesk';
  margin-bottom: 25px;
}
.margin-botton-head {
  margin-bottom: 4px !important;
}

/* .loginSectionWelcome {
  background-image: url('../../assests/images/Welcome.jpg');
  background-repeat: no-repeat;
  background-size: cover;
} */

.plan-div {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5%;
  margin-left: 5%;
}

.loginSectionWelcome {
  background: url('../../assests/images/bg-top.png') top right no-repeat,
    url('../../assests/images/bg-bottom.png') bottom left no-repeat;
  background-size: 21%, 23%;
  height: 100vh;
}

.planSectionWelcome {
  background: url('../../assests/images/bg-top.png') top right no-repeat,
    url('../../assests/images/bg-bottom.png') bottom left no-repeat;
  background-size: 21%, 23%;
  height: 100vh;
}
.forgotDescriptionText {
  color: #292b2d;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
}

.verifyFormWrapCard {
  width: 450px;
  background-color: #fff;
  padding: 13px 20px;
  border-radius: 23px;
  box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.1);
}

.loginFormWrp {
  width: 450px;
  background-color: #fff;
  padding: 13px 20px;
  border-radius: 23px;
  box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.1);
}

.email-forget-icon-width {
  width: 355px !important;
}

.emailForm {
  width: 355px !important;
  padding: 13px 40px;
  border-radius: 23px;
}

.emailText {
  color: #240f51;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.registerText {
  color: #240f51;
  font-size: 26px;
  font-style: normal;
  line-height: normal;
  margin-bottom: 0 !important;
  margin-top: 15px;
  font-family: Neurialgrotesk, sans-serif;
  font-weight: 700;
}

.font-500 {
  font-weight: 500;
}

.welcomeText {
  color: #240f51;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 5px !important;
  margin-top: 15px;
  font-family: Neurialgrotesk, sans-serif;
}

.logoCss {
  width: 100% !important;
}
.logoCss svg {
  width: 100%;
}

.tagtext {
  color: #292b2d;
  font-size: 14px;
  font-weight: 400;
  font-family: Neurialgrotesk, sans-serif;
}
.FormCheck {
  color: #000;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.open-email-btn {
  padding: 16px 92px !important;
  box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.1);
}

.open-reset-btn {
  padding: 16px 95px !important;
  box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.1);
}

.reset-password-text {
  color: #240f51;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.plans-div {
  width: 1051px !important;
  background-color: #fff;
  /* padding: 0; */
  border-radius: 23px;
  box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.1);
}

.plans-div-text {
  color: #240f51;
  font-size: 23px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 0 !important;
}

.email-gif {
  width: 60%;
  height: 60%;
  margin-top: 20px;
}

.akahu-model-btn {
  padding: 16px 117px !important;
  box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.1);
  font-family: Neurialgrotesk, sans-serif;
  font-weight: 500;
}

/* Checked state styles */
input:checked + .slider {
  background-color: rgba(20, 91, 255, 1);
}

input:checked + .slider:before {
  transform: translateX(18px);
}

.slider {
  background-color: rgba(20, 91, 255, 1);
}

.select-plans-div-text {
  color: #ffffff;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 0 !important;
}
.select-plans-text {
  color: #ffffff;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 0 !important;
  margin-top: 5px;
}

.select-plan-button {
  width: 257px;
  height: 46px;
  background-color: #fefefe;
  border-radius: 10px;
  color: #145bff;
  font-weight: 700;
  font-size: 14px;
  border: none;
  margin-top: 20px;
}
.select-plan-button-green {
  width: 100%;
  height: 46px;
  background-color: #fefefe;
  border-radius: 10px;
  font-family: Neurialgrotesk, sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  border: none;
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 10px;
  transition: background-color 0.3s ease;
}
.select-plan-button-green:hover,
.select-plan-button-green:active {
  color: rgb(255, 255, 255) !important;
  background-color: rgba(254, 254, 254, 0.4) !important;
}
.select-plan-button-purple {
  width: 257px;
  height: 46px;
  background-color: #fefefe;
  border-radius: 10px;
  color: #8914ff;
  font-weight: 700;
  font-size: 14px;
  border: none;
  margin-top: 20px;
}

.select-plan-hr {
  background-color: #ffffff;
  opacity: 1 !important;
  width: 257px;
  margin-top: 30px;
}

.select-plans-description-text {
  color: #ffffff;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 0 !important;
}

.confirm-plan {
  width: 223px;
  height: 45px;
  background-color: #469b88;
  border-radius: 10px;
  color: #ffffff;
  font-weight: 500;
  font-size: 14px;
  border: none;
  margin-top: 20px;
}
.plan-selected-text {
  font-size: 17px;
  font-weight: 500;
  line-height: 19px;
  font-family: Neurialgrotesk, sans-serif;
}
.plan-selected-manage-text {
  font-weight: 400 !important;
  line-height: 19px;
  font-family: Neurialgrotesk, sans-serif;
}
.app-logo-icon {
  width: 100%;
}

.store-logos {
  display: flex;
  justify-content: space-evenly;
  margin-top: 30px;
  width: 85%;
}
.social-logos {
  display: flex;
  justify-content: space-evenly;
  margin-top: 30px;
  width: 475px;
}

.plan-text-selected {
  margin: auto;
  display: inline-block;
  margin-left: 102px;
}

.register-focus:focus {
  border: none;
}
.welcome-form-input-box {
  box-shadow: 0px 5px 30px 0px #0000001a;
  border: 0;
  border-radius: 10px;
  max-width: 100%;
  height: 55px;
}

.feedback-form-input-box {
  border: 2px solid #e4e4e7;
  border-radius: 10px;
  max-width: 100%;
  margin: 35px 20px 35px 20px;
}

.welcome-form-input-text {
  color: #8f94a3;
  font-size: 10px;
  font-weight: 600;
}

.input-header-text {
  font-family: Neurialgrotesk, sans-serif;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 3px;
  margin-left: 2px;
  margin-top: 10px;
}

.input-header-text-manage {
  font-family: Neurialgrotesk, sans-serif;
  font-weight: 500;
  font-size: 17px;
  margin-bottom: 3px;
  margin-left: 2px;
  margin-top: 10px;
}

.input-text-box-margin {
  padding: 10px 13px 10px 12px;
}
.input-text-box-feedback-margin {
  padding: 10px 13px 10px 12px;
}
.checkbox-welcome-new {
  width: 16px;
  height: 16px;
  padding: 0;
  margin-right: 6px;
  margin-left: 4px;
  margin-top: 3px;
  display: inline-block;
  vertical-align: middle;
}
.input-checkbox-welcome {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none; /* Remove default outline */
  /* Define the size and shape of the custom checkbox */
  width: 16px;
  height: 16px;
  margin: 0;
  padding: 0;
  border: 2px solid rgba(70, 155, 136, 1); /* Set border color */
  border-radius: 4px; /* Round corners */
  /* Add space between the checkbox and the text */
  margin-right: 10px;
  margin-left: 2px;
  margin-top: 1px;
  display: inline-block;
  vertical-align: middle;
}

.input-checkbox-welcome:checked {
  border-color: rgba(70, 155, 136, 1); /* Set checked border color */
  background-color: blue;
}
.input-checkbox-welcome:checked::before {
  content: '\2713';
  font-size: 12px;
  font-weight: 700;
  color: white;
  position: relative;
  top: -2px;
  left: 2px;
  transform: translate(-50%, -50%);
}
.check-label-text {
  margin: 0;
  font-family: Neurialgrotesk, sans-serif;
  font-weight: 400;
}

.welcome-plan-text {
  text-align: center;
  width: 389px;
  height: 40px;
}

.payment-gif {
  width: 280px;
  height: 270px;
}

.verification-box:focus {
  border-color: #5796e9 !important;
}

@media only screen and (max-width: 600px) {
  .loginFormWrp {
    max-width: 95% !important;
    margin-bottom: 18px;
    margin-right: 10%;
    margin-left: 10%;
  }
  .logoCss {
    width: 100% !important;
  }
  .logoCss svg {
    width: 196px;
  }
  .sign-btn {
    padding: 12px 116px !important;
  }
  .sign-btn-load {
    padding: 12px 135px !important;
  }
  .store-logos {
    display: inline-grid !important;
  }
  .store-logos-img {
    margin-bottom: 10px !important;
  }

  .welcomeText {
    color: #240f51;
    font-size: 24px !important;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 15px;
    margin-bottom: 8px !important;
  }
  .tagtext {
    color: #292b2d;
    font-size: 13px;
    margin-left: 0px !important;
    font-weight: 400;
  }
  .free-trial {
    height: 50px !important;
  }
  .standard-month {
    height: 50px !important;
  }
  .pro-month {
    height: 50px !important;
  }

  .standard-year {
    height: 50px !important;
  }
  .pro-year {
    height: 50px !important;
  }

  .standard-month-current-plan {
    height: 50px !important;
  }

  .standard-year-current-plan {
    height: 50px !important;
  }

  .pro-month-current-plan {
    height: 50px !important;
  }

  .pro-year-current-plan {
    height: 50px !important;
  }

  .free-trial-current-paln {
    height: 50px !important;
  }

  .no-current-plan {
    height: 50px !important;
  }
  .cancel-plan-btn {
    padding: 16px 38px !important;
  }
  .social-logos {
    margin-top: 30px;
    width: 370px;
    margin-left: 12px;
  }
  .welcome-form-input-text {
    color: #8f94a3;
    font-size: 10px !important;
    font-weight: 600;
  }
  .loginSectionWelcome {
    background: url('../../assests/images/bg-top.png') top right no-repeat,
      url('../../assests/images/bg-bottom.png') bottom left no-repeat;
    background-size: 35%, 60%;
    overflow-y: auto;
    scroll-behavior: smooth;
  }

  .loginSectionWelcomeBottom {
    background-image: url('../../assests/images/bg-bottom.png');
    background-repeat: no-repeat;
    background-size: 30%;
    background-position: bottom left;
  }
  .registerText {
    color: #240f51;
    font-size: 25px;
    font-style: normal;
    font-weight: 700 !important;
    line-height: normal;
    margin-bottom: 4px !important;
    margin-top: 15px;
  }
  .padding-none {
    padding-left: 0px !important;
  }
  .input-checkbox-welcome {
    width: 22px;
    height: 17px;
    margin-right: 6px;
  }
  .store-image {
    margin-top: 10px !important;
    width: auto !important;
    justify-content: center !important;
  }
  .verification-box {
    width: 39px;
    height: 39px;
    margin-right: 3px;
    margin-left: 3px;
    border: 2px solid #d0d0d0;
  }
  .email-gif {
    width: 60%;
    height: 60%;
  }
  .verification-box:focus {
    border-color: #5796e9 !important;
  }
}

@media only screen and (max-width: 390px) {
  .loginFormWrp {
    max-width: 95% !important;
    margin-bottom: 18px;
    margin-right: 10%;
    margin-left: 10%;
  }
  .logoCss {
    width: 100% !important;
  }
  .logoCss svg {
    width: 196px;
  }
  .sign-btn {
    padding: 12px 102px !important;
  }
  .sign-btn-load {
    padding: 12px 121px !important;
  }
  .store-logos {
    display: inline-grid !important;
  }
  .store-logos-img {
    margin-bottom: 10px !important;
    width: 150px !important;
    height: 60px !important;
  }

  .welcomeText {
    color: #240f51;
    font-size: 24px !important;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 15px;
    margin-bottom: 8px !important;
  }
  .tagtext {
    color: #292b2d;
    font-size: 12px;
    margin-left: 0px !important;
    font-weight: 400;
  }
  .free-trial {
    height: 45px !important;
  }
  .standard-month {
    height: 45px !important;
  }
  .pro-month {
    height: 45px !important;
  }

  .standard-year {
    height: 45px !important;
  }
  .pro-year {
    height: 45px !important;
  }

  .standard-month-current-plan {
    height: 45px !important;
  }

  .standard-year-current-plan {
    height: 45px !important;
  }

  .pro-month-current-plan {
    height: 45px !important;
  }

  .pro-year-current-plan {
    height: 45px !important;
  }

  .free-trial-current-paln {
    height: 45px !important;
  }

  .no-current-plan {
    height: 45px !important;
  }

  .cancel-plan-btn {
    padding: 16px 33px !important;
  }

  .social-logos {
    margin-top: 30px;
    width: 345px;
    margin-left: 12px;
  }
  .welcome-form-input-text {
    color: #8f94a3;
    font-size: 10px !important;
    font-weight: 600;
  }
  .loginSectionWelcome {
    background: url('../../assests/images/bg-top.png') top right no-repeat,
      url('../../assests/images/bg-bottom.png') bottom left no-repeat;
    background-size: 35%, 60%;
  }

  .loginSectionWelcomeBottom {
    background-image: url('../../assests/images/bg-bottom.png');
    background-repeat: no-repeat;
    background-size: 30%;
    background-position: bottom left;
  }
  .planSectionWelcome {
    background: url('../../assests/images/bg-top.png') top right no-repeat,
      url('../../assests/images/bg-bottom.png') bottom left no-repeat;
    background-size: 35%, 60%;
  }
  .welcome-plan-text {
    text-align: center;
    width: 250px;
    height: 40px;
  }
  .registerText {
    color: #240f51;
    font-size: 22px;
    font-style: normal;
    font-weight: 700 !important;
    line-height: normal;
    margin-bottom: 0 !important;
    margin-top: 15px;
  }
  .padding-none {
    padding-left: 0px !important;
  }
  .store-image {
    margin-top: 10px !important;
    width: auto !important;
    justify-content: center !important;
  }
  .app-logo-icon {
    width: 100%;
  }
  .verification-box {
    width: 38px;
    height: 38px;
    margin-right: 2px;
    margin-left: 2px;
    border: 2px solid #d0d0d0;
  }
  .email-gif {
    width: 60%;
    height: 60%;
  }
  .payment-gif {
    width: 260px;
    height: 250px;
  }
  input.verification-box:focus {
    border-color: #5796e9 !important;
  }
}

@media only screen and (max-width: 380px) {
  .loginFormWrp {
    max-width: 95% !important;
    margin-bottom: 18px;
    margin-right: 10%;
    margin-left: 10%;
  }
  .logoCss {
    width: 100% !important;
    margin-left: 0;
  }
  .logoCss svg {
    width: 196px;
  }
  .sign-btn {
    padding: 12px 102px !important;
  }

  .sign-btn-load {
    padding: 12px 120px !important;
  }
  .store-logos {
    display: inline-grid !important;
  }
  .store-logos-img {
    margin-bottom: 10px !important;
    width: 150px !important;
    height: 60px !important;
  }

  .welcomeText {
    color: #240f51;
    font-size: 22px !important;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 15px;
    margin-bottom: 8px !important;
  }
  .tagtext {
    color: #292b2d;
    font-size: 12px;
    margin-left: 0px !important;
  }
  .free-trial {
    height: 40px !important;
  }

  .standard-month {
    height: 40px !important;
  }

  .pro-month {
    height: 40px !important;
  }
  .standard-year {
    height: 40px !important;
  }
  .pro-year {
    height: 40px !important;
  }
  .standard-month-current-plan {
    height: 40px !important;
  }

  .standard-year-current-plan {
    height: 40px !important;
  }

  .pro-month-current-plan {
    height: 40px !important;
  }

  .pro-year-current-plan {
    height: 40px !important;
  }

  .free-trial-current-paln {
    height: 40px !important;
  }

  .no-current-plan {
    height: 40px !important;
  }

  .cancel-plan-btn {
    padding: 16px 28px !important;
  }
  .social-logos {
    margin-top: 30px;
    width: 325px;
    margin-left: 12px;
  }
  .welcome-form-input-text {
    color: #8f94a3;
    font-size: 10px !important;
    font-weight: 600;
  }
  .loginSectionWelcome {
    background: url('../../assests/images/bg-top.png') top right no-repeat,
      url('../../assests/images/bg-bottom.png') bottom left no-repeat;
    background-size: 35%, 60%;
  }

  .planSectionWelcome {
    background: url('../../assests/images/bg-top.png') top right no-repeat,
      url('../../assests/images/bg-bottom.png') bottom left no-repeat;
    background-size: 35%, 60%;
  }
  .loginSectionWelcomeBottom {
    background-image: url('../../assests/images/bg-bottom.png');
    background-repeat: no-repeat;
    background-size: 30%;
    background-position: bottom left;
  }

  .welcome-plan-text {
    text-align: center;
    width: 250px;
    height: 40px;
  }
  .padding-none {
    padding-left: 0px !important;
  }
  .store-image {
    margin-top: 10px !important;
    width: auto !important;
    justify-content: center !important;
  }
  .verification-box {
    width: 38px;
    height: 38px;
    margin-right: 3px;
    margin-left: 3px;
  }
  .email-gif {
    width: 60%;
    height: 60%;
  }
  input.verification-box:focus {
    border-color: #5796e9 !important;
  }
}

@media only screen and (max-width: 430px) {
  .loginFormWrp {
    max-width: 95% !important;
    margin-bottom: 18px;
  }
  .logoCss {
    width: 100% !important;
    align-content: center;
  }
  .logoCss svg {
    width: 196px;
  }
  .sign-btn {
    padding: 12px 102px !important;
  }

  .sign-btn-load {
    padding: 12px 120px !important;
  }
  .store-logos {
    display: inline-grid !important;
  }
  .store-logos-img {
    margin-bottom: 10px !important;
    width: 150px !important;
    height: 60px !important;
  }

  .welcomeText {
    color: #240f51;
    font-size: 6vw !important;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 15px;
    margin-bottom: 8px !important;
  }
  .tagtext {
    color: #292b2d;
    font-size: 3.4vw;
    margin-left: 0px !important;
  }
  .free-trial {
    height: 40px !important;
  }

  .standard-month {
    height: 40px !important;
  }

  .pro-month {
    height: 40px !important;
  }
  .standard-year {
    height: 40px !important;
  }
  .pro-year {
    height: 40px !important;
  }
  .standard-month-current-plan {
    height: 40px !important;
  }

  .standard-year-current-plan {
    height: 40px !important;
  }

  .pro-month-current-plan {
    height: 40px !important;
  }

  .pro-year-current-plan {
    height: 40px !important;
  }

  .free-trial-current-paln {
    height: 40px !important;
  }

  .no-current-plan {
    height: 40px !important;
  }

  .cancel-plan-btn {
    padding: 16px 28px !important;
  }
  .social-logos {
    margin-top: 30px;
    width: 325px;
    margin-left: 12px;
  }
  .welcome-form-input-text {
    color: #8f94a3;
    font-size: 10px !important;
    font-weight: 600;
  }
  .loginSectionWelcome {
    background: url('../../assests/images/bg-top.png') top right no-repeat,
      url('../../assests/images/bg-bottom.png') bottom left no-repeat;
    background-size: 35%, 60%;
  }

  .planSectionWelcome {
    background: url('../../assests/images/bg-top.png') top right no-repeat,
      url('../../assests/images/bg-bottom.png') bottom left no-repeat;
    background-size: 35%, 60%;
  }
  .loginSectionWelcomeBottom {
    background-image: url('../../assests/images/bg-bottom.png');
    background-repeat: no-repeat;
    background-size: 30%;
    background-position: bottom left;
  }

  .welcome-plan-text {
    text-align: center;
    width: 250px;
    height: 40px;
  }
  .padding-none {
    padding-left: 0px !important;
    margin-right: 5%;
    margin-left: 5%;
  }
  .store-image {
    margin-top: 10px !important;
    width: auto !important;
    justify-content: center !important;
  }
  .verification-box {
    width: 38px;
    height: 38px;
    margin-right: 3px;
    margin-left: 3px;
  }
  .email-gif {
    width: 60%;
    height: 60%;
  }
  input.verification-box:focus {
    border-color: #5796e9 !important;
  }
}
