/* Modal Styles */
.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.20);
  display: none;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-background.open-modal {
  display: flex;
  animation: fadeIn 0.3s ease;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.10);
  position: relative;
  width: 55%;
}

.remove-modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.10);
  position: relative;
  width: 20%;
}

.edit-modal-content {
  background-color: #fff;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 30px;
  padding-left: 30px;
  border-radius: 10px;
  box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.10);
  position: relative;
  width: 55%;
}

.close-modal-button {
  /* position: absolute; */
  /* top: 20px;
  right: 10px; */
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  outline: none;
  font-weight: 500;
}

/* Button Styles */
.open-modal-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.open-modal-button:hover {
  background-color: #0056b3;
}

.date-picker-style input[type='text'] {
  height: 48px;
  font-size: 18px !important;
  border-radius: 10px !important;
  background-color: #fdfdfd !important;
  border: 1px solid #ced4da;
  width: 100%;
  display: block;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  padding: 0px 10px;
  font-weight: 400;
  line-height: 1.5;
}
.date-picker-style input[type='text']::placeholder {
  color: #212529;
}
.react-datepicker-wrapper.date-picker-style.full-width {
  width: 100%;
}

.date-picker-div {
  position: relative;
}

.date-picker-div i {
  position: absolute;
  right: 5%;
  top: 15px;
  cursor: pointer;
}

.popup-text {
  font-size: 13px !important;
}

.labels-text {
  font-size: 18px;
}

.add_income-wrp .input_border::placeholder {
  color: #565a6d !important;
}

.add_income-wrp .input_border {
  color: #565a6d !important;
}

.add-expense-wrp .input_border::placeholder {
  color: #565a6d !important;
}

.add-expense-wrp .input_border {
  color: #565a6d !important;
}


.connect-title-mb{
  margin-bottom: 15px;
}

