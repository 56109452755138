  .custom-input {
    background-color: #efeded;
    font-size: 14px;
    border-radius: 10px;
    font-weight: 500;
    text-align: center;
    position: relative;
    height: 35px;
    border: none;
    outline: none; 
  }