.connected-account-box {
  display: flex;
  background-color: #fff;
  padding: 12px;
  border-radius: 8px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
  box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.1);
  height: 65px;
}
.connected-title {
  color: #242424;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
}
.connected-amount{
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 140%;
}
