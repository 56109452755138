.add-modal-width {
  width: 490px !important;
  border-radius: 20px !important;
}

.add-modal-padding {
  padding:20px 31px 31px 31px !important
}

.add-modal-padding-category {
  padding:15px 31px 20px 31px !important
}

.add-rule-width {
  width: 540px !important;
  border-radius: 20px !important;
  padding:20px 31px 31px 31px !important
}

.pending-modal-width {
  width: 360px;
  border-radius: 20px !important;
  padding: 18px;
}

.header-budget-main-div {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.header-budget-sub-div {
  width: 70%;
  display: inline-flex;
  /* align-items: center; */
  justify-content: flex-end;
}

.header-budget-text-div {
  width: 100%;
  margin-top: -18px;
}

.pending-btn-model {
  display: flex;
  width: 100%;
  padding: 10px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 12px;
  background: #469b88;
  border: none;
  color: white;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: -5px;
}

.pending-modal-para {
  color: var(--Base-100, #2c3131);
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  margin-bottom: 18px;
  text-align: center;
  font-family: 'Neurialgrotesk';
}

.pending-heading {
  font-size:26px;
  color: #0e132f;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: -0.32px;
  text-align: center;
  font-family: 'NeurialGrotesk';
}

.see-all {
  color: #469b88;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  font-family: 'Neurialgrotesk';
  padding-top: 5px;
  padding-right: 8px;
}

.sticky-sidebar {
  position: -webkit-sticky; 
  position: sticky;
  top: 0px; 
  height: auto;
  align-self: start; 
  z-index: 10; 
}

.account-detail-left {
  overflow-y: visible;
  padding-right: 15px;
}

.account-detail-right {
  height: 100%;
}

.search-box-input {
  width: 231px;
  height: 45px;
  border-radius: 8px;
  background: #ffffff;
  padding: 10px;
  font-family: 'Neurialgrotesk';
  font-weight: 400;
  border: 0px;
  box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.10);
}

.subcategory-text-detail {
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  font-family: 'Neurialgrotesk';
}

.split-modal {
  width: 490px !important;
  border-radius: 15px !important;
  padding: unset !important;
}
.trans-modal-para {
  color: var(--Base-100, #2c3131);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  margin-bottom: 30px;
}
.show-drawer{
  width: 173px;
height: 31px;
}
.add-transaction-btn {
  display: flex;
  width: 140px;
  height: 50px;
  padding: 9px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  background: #469b88;
  box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.1);
  color: white;
  margin-right: 10px;
  font-size: 14px;
  cursor: pointer;
  border: none;
}

.add-transaction-rules-btn {
  display: flex;
  width: 100%;
  height: 50px;
  padding: 9px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  background: #469b88;
  box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.1);
  color: white;
  margin-right: 10px;
  font-size: 16px;
  cursor: pointer;
  border: none;
}

.rule-list-scrollable {
  overflow-y: scroll; 
}

.rule-list-scrollable::-webkit-scrollbar {
  display: none;
}

.rule-list-scrollable {
  scrollbar-width: none;
}


.delete-asset-btn {
  display: flex;
  width: 100px;
  height: 50px;
  padding: 9px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  background: #D04F4F;
  box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.1);
  color: white;
  /* margin-right: 35px; */
  font-size: 14px;
  cursor: pointer;
  border: none;
}

.transaction-view-img {
  width: 135px;
  height: 135px;
  border-radius: 50%;
  object-fit: cover;
}
.view-amount {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
}

.view-date {
  color: #9b9b9b;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
}

.view-desc {
  color: #4c4c4c;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}
.view-amount-sm {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
}

.view-date-sm {
  color: #434343;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}
.split-btn {
  width: 100%;
  display: flex;
  height: 58px;
  padding: 9px 85.5px 9px 18.5px;
  gap: 22px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  background: var(--Base-0, #fff);
  box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}
.split-here {
  color:#242424;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%
}
.add-split {
  display: flex;
  width: 231px;
  height: 49px;
  padding: 10px 15px 9px 10px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  background: var(--Base-0, #fff);
  box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.1);
  gap: 24px;
  color: var(--Base-100, #242424);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 22.4px */
  border: none;
}
.split-btn-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 51px;
}
.split-transaction-text {
  font-size: 14px;
  text-align: center;
  font-weight: 400;
  margin-bottom: 5px;
}
.split-transaction-text-amount {
  font-size: 14px;
  text-align: center;
  font-weight: 500;
  margin-bottom: 5px;
}
.split-transac {
  display: flex;
width: 426px;
padding: 10px 24px;
justify-content: center;
align-items: center;
gap: 8px;
border-radius: 12px;
background: #469B88;
border: none;
color: white;
font-size: 16px;
font-weight: 500;
}

.sp-container{
  overflow-y: auto;
  height: 300px;
  padding: 0px 31px;
}
.sp-container::-webkit-scrollbar {
  display: none; /* Hide the scrollbar */
;
}
.popup-btn-width{
  width: 125px;
}
.split-pd{
  padding-left:31px !important;
  padding-right:31px !important;

}

.detail-account-title {
color: #242424;
font-size: 15px;
font-style: normal;
font-weight: 500;
line-height: 140%;
}

.split-title {
  color: #242424;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: 130%;
margin-bottom: 9px;
}

.account-title{
  color:#242424;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: 130%;
margin-bottom: 12px;
}

.small-text-detail {
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
}

.note-text-detail {
  font-size: 14px;
  font-weight: 500;
  padding-top: 20px;
  margin-right: 2px;
}

.note-details-text-detail {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  padding-top: 20px;
}

.note-details-text-detail-2 {
  font-size: 14px;
  font-weight: 400;
  display: flex;
  padding-top: 6px;
}

.single-catefory-icon-tag {
  display: flex;
  align-items: center;
  border-radius: 20px;
  border: 0.5px solid #CACACA;
  width: auto;
  padding: 2px;
  cursor: pointer;
  transition: color 0.3s ease;
  height: 27px;
}
.all-btn {
  font-size: 15px;
  transition: 0.3s ease;
  margin-bottom: 6px;
  color: black;
  text-align: center;
  border: 0.5px solid #CACACA;
  background-color: #ffffff;
  line-height: 19px;
  display: flex;
  padding: 6px 15px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 30px;
  height: 27px;
  margin-bottom: 8px;
}
.split-account-title{
  color:#242424;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 140%;
}

.account-box {
  display: flex;
  background-color: #fff;
  padding: 12px;
  border-radius: 8px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
  box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.10);
  height: 65px;
}

.split-amount {
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 140%;
}

.detail-account-container{
display: flex;
height: 63px;
padding: 12px;
align-items: center;
flex-shrink: 0;
border-radius: 8px;
align-items: center;
justify-content: space-between;
width: 100%;
cursor: pointer;
box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.10);
}

.categories-scroll-container {
  max-height: 300px;
  overflow-y: auto;
  padding-right: 10px;
}

.categories-scroll-container::-webkit-scrollbar {
  width: 8px;
}

.categories-scroll-container::-webkit-scrollbar-thumb {
  background: #4caf50;
  border-radius: 10px;
}

.categories-scroll-container::-webkit-scrollbar-track {
  background: transparent; 
}

.categories-scroll-container {
  scrollbar-width: thin; 
  scrollbar-color: #4caf50 transparent;
}

.img-box-merchant-small {
  height: 20px;
  width: 20px;
  display: inline-block;
  display: flex;
  margin-left: 2px;
  align-items: center;
  justify-content: center;
}